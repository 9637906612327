import React from 'react';
import Accordion from 'sections/accordion';
import BigHero from 'sections/big-hero';
import CardBlock from 'sections/card-block';
import CaseBlock from 'sections/case-block';
import CaseLink from 'sections/case-link';
import Diagram from 'sections/diagram';
import Form from 'sections/form';
import Hero from 'sections/hero';
import IconTextList from 'sections/icon-text-list';
import MediaComponent from 'sections/media-component';
import Spacing from 'sections/spacing';
import Testimonial from 'sections/testimonial';
import TitleAndImage from 'sections/title-and-image';
import TwoTextColumn from 'sections/two-text-column';
import TwoTextImageColumn from 'sections/two-text-image-column';
import UtilisedNavigation from 'sections/utilised-navigation';
import { range } from './functions';

export type Blocks =
  | GatsbyTypes.AccordionFragment
  | GatsbyTypes.AccordionEndFragment
  | GatsbyTypes.BigHeroFragment
  | GatsbyTypes.CardBlockFragment
  | GatsbyTypes.CaseLinkFragment
  | GatsbyTypes.CaseBlockFragment
  | GatsbyTypes.DiagramFragment
  | GatsbyTypes.FormFragment
  | GatsbyTypes.HeroFragment
  | GatsbyTypes.IconTextListFragment
  | GatsbyTypes.MediaComponentFragment
  | GatsbyTypes.SpacingFragment
  | GatsbyTypes.TestimonialFragment
  | GatsbyTypes.TitleAndImageFragment
  | GatsbyTypes.TwoTextColumnFragment
  | GatsbyTypes.TwoTextImageColumnFragment
  | GatsbyTypes.UtilisedNavigationFragment;

type RenderBlocksProps = {
  data: Blocks[];
};

const RenderBlocks: React.FC<RenderBlocksProps> = ({ data }) => {
  const accordionStarts = data
    .map(({ __typename }, idx) => (__typename === 'DatoCmsAccordionStart' ? idx : -1))
    .filter((i) => i >= 0);
  const accordionEnds = data
    .map(({ __typename }, idx) => (__typename === 'DatoCmsAccordionEnd' ? idx : -1))
    .filter((i) => i >= 0);

  const accordionLists: Blocks[][] = [];
  let blackList: number[] = [];

  accordionStarts.forEach((startIdx, idx, starts) => {
    let endIdx = accordionEnds.find((i) => i > startIdx) ?? data.length;
    const nextStart = starts.find((i) => i > startIdx) ?? data.length;
    endIdx = endIdx <= nextStart ? endIdx : nextStart;

    blackList = [...range(startIdx, endIdx), ...blackList];
    accordionLists.push(data.filter((_, idx) => idx > startIdx && idx < endIdx));
  });

  let accordionIdx = 0;
  let accordionCount = 0;

  return (
    <>
      {data.map((block, index, blockList) => {
        if (
          blackList.includes(index) &&
          block.__typename !== 'DatoCmsAccordionStart' &&
          block.__typename !== 'DatoCmsAccordionEnd'
        ) {
          return null;
        }

        if (block.__typename === 'DatoCmsAccordionEnd') {
          accordionIdx = 0;
          accordionCount++;
        }

        switch (block.__typename) {
          case 'DatoCmsBigHero':
            return <BigHero key={block.id} {...block} />;
          case 'DatoCmsHero':
            return <Hero key={block.id} {...block} />;
          case 'DatoCmsMediaComponent':
            return <MediaComponent key={block.id} {...block} />;
          case 'DatoCmsTwoTextColumn':
            return <TwoTextColumn key={block.id} {...block} />;
          case 'DatoCmsSpacing':
            return <Spacing key={block.id} {...block} />;
          case 'DatoCmsIconTextList':
            return <IconTextList key={block.id} {...block} />;
          case 'DatoCmsTwoTextImageColumn':
            return <TwoTextImageColumn key={block.id} {...block} />;
          case 'DatoCmsTestimonial':
            return <Testimonial key={block.id} {...block} />;
          case 'DatoCmsCaseBlock':
            return <CaseBlock key={block.id} {...block} />;
          case 'DatoCmsCardBlock':
            return <CardBlock key={block.id} {...block} />;
          case 'DatoCmsUtilisedNavigation':
            return <UtilisedNavigation key={block.id} {...block} />;
          case 'DatoCmsCaseLink':
            return <CaseLink key={block.id} {...block} />;
          case 'DatoCmsDiagram':
            return <Diagram key={block.id} {...block} />;
          case 'DatoCmsAccordionStart':
            return (
              <Accordion
                key={block.id}
                index={++accordionIdx}
                blocks={accordionLists.shift()}
                endObject={
                  blockList[accordionEnds[accordionCount]] as GatsbyTypes.AccordionEndFragment
                }
                {...block}
              />
            );
          case 'DatoCmsForm':
            return <Form key={block.id} {...block} />;
          case 'DatoCmsSubAndImage':
            return <TitleAndImage key={block.id} {...block} />;

          default:
            return null;
        }
      })}
    </>
  );
};

export default RenderBlocks;
