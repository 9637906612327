import Bevel from 'components/bevel';
import Column from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import * as styles from './media-component.module.scss';

const MediaComponent: React.FC<GatsbyTypes.MediaComponentFragment> = ({
  textNode,
  bigImage,
  smallImage,
}) => {
  return (
    <Section innerGap>
      <Column xl={6} lg={6} md={6} sm={6}>
        <Image className={styles.mediaComponentImage} {...bigImage} />
      </Column>
      <Column className={styles.mediaComponentRight} xl={6} lg={6} md={6} sm={6}>
        <Column className={styles.mediaComponentRightImage}>
          <Image className={styles.mediaComponentImage} {...smallImage} />
        </Column>
        <Column className={styles.mediaComponentRightText}>
          <div>
            {textNode?.childMdx?.body && <MDXRenderer>{textNode.childMdx.body}</MDXRenderer>}
          </div>
          <Bevel size={'large'} color={'milk'} position={'bottomRight'} />
        </Column>
      </Column>
    </Section>
  );
};

export default MediaComponent;

export const query = graphql`
  fragment MediaComponent on DatoCmsMediaComponent {
    __typename
    id
    textNode {
      childMdx {
        body
      }
    }
    bigImage {
      ...Image
    }
    smallImage {
      isImage
      mimeType
      gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "2", fit: "crop" })
      alt
      url
    }
  }
`;
