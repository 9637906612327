import Column from 'components/column';
import Icon from 'images/svg/icon.inline.svg';
import Logo from 'images/svg/logo-white.inline.svg';
import Link, { LinkProps } from 'components/link';
import clsx from 'clsx';
import Headroom from 'react-headroom';
import React from 'react';

import * as styles from './header.module.scss';

const MobileMenu: React.FC<Pick<GatsbyTypes.HeaderQueryQuery, 'datoCmsHeaderMenu'>> = ({
  datoCmsHeaderMenu,
}) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(false);

  return (
    <>
      <Headroom
        className={clsx(styles.headroom, { [styles.headroomOpen]: isOpen })}
        disable={isOpen}
        disableInlineStyles
        upTolerance={0}
      >
        <Column className={styles.mobileMenu}>
          <Column className={styles.mobileMenuColumn} sm={2} xs={2}>
            <Link
              onClick={() => setIsOpen(false)}
              internalLink={{ model: { apiKey: 'start_page' } }}
            >
              <Icon />
            </Link>
          </Column>
          <Column
            className={clsx(styles.mobileMenuColumn, styles.mobileMenuColumnCenter)}
            sm={8}
            xs={8}
          >
            <Link
              onClick={() => setIsOpen(false)}
              className={styles.mobileMenuLink}
              internalLink={{ model: { apiKey: 'start_page' } }}
            >
              <Logo />
            </Link>
          </Column>
          <Column
            className={clsx(styles.mobileMenuColumn, styles.mobileMenuColumnRight)}
            sm={2}
            xs={2}
          >
            <div
              className={clsx(styles.hamburger, { [styles.hamburgerOpen]: isOpen })}
              onClick={() => setIsOpen((o) => !o)}
            >
              <div className={styles.hamburgerIcon}>
                <span />
              </div>
            </div>
          </Column>
        </Column>
      </Headroom>
      <div className={clsx(styles.mobileMenuOverlay, { [styles.mobileMenuOverlayOpen]: isOpen })}>
        <div className={styles.mobileMenuOverlayContainer}>
          {datoCmsHeaderMenu?.links?.map(
            (link) =>
              link && (
                <Link
                  className={styles.mobileMenuLink}
                  activeClassName={styles.mobileMenuLinkActive}
                  onClick={() => setIsOpen(false)}
                  key={link.id}
                  {...(link as LinkProps)}
                >
                  {link.text}
                </Link>
              )
          )}
        </div>
      </div>
    </>
  );
};

export default MobileMenu;
