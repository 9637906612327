import WidthSvg from 'images/svg/width.inline.svg';
import HeightSvg from 'images/svg/height.inline.svg';
import ArrowBendUpSvg from 'images/svg/arrow-bend-up.inline.svg';
import ArrowBendDownSvg from 'images/svg/arrow-bend-down.inline.svg';
import CheckSvg from 'images/svg/check.inline.svg';
import ArrowUp from 'images/svg/arrow-up.inline.svg';
import React from 'react';

import * as styles from './icon.module.scss';
import clsx from 'clsx';

type IconProps = {
  color?: string;
};

export const WidthIcon: React.FC<IconProps> = ({ children, color }) => {
  return (
    <div className={clsx(styles.icon, (styles as never)[`iconBG${color}`])}>
      <WidthSvg /> <div>{children}</div>
    </div>
  );
};
export const HeightIcon: React.FC<IconProps> = ({ children, color }) => {
  return (
    <div className={clsx(styles.icon, (styles as never)[`iconBG${color}`])}>
      <HeightSvg /> <div>{children}</div>
    </div>
  );
};
export const UpArrowIcon: React.FC<IconProps> = ({ children, color }) => {
  return (
    <div className={clsx(styles.icon, (styles as never)[`iconBG${color}`])}>
      <ArrowBendUpSvg /> <div>{children}</div>
    </div>
  );
};
export const DownArrowIcon: React.FC<IconProps> = ({ children, color }) => {
  return (
    <div className={clsx(styles.icon, (styles as never)[`iconBG${color}`])}>
      <ArrowBendDownSvg /> <div>{children}</div>
    </div>
  );
};
export const CheckIcon: React.FC<IconProps> = ({ children, color }) => {
  return (
    <div className={clsx(styles.icon, (styles as never)[`iconBG${color}`])}>
      <CheckSvg /> <div>{children}</div>
    </div>
  );
};
export const CircledArrowIcon: React.FC<IconProps> = ({ children, color }) => {
  return (
    <div className={clsx(styles.icon, (styles as never)[`iconBG${color}`])}>
      <ArrowUp /> <div>{children}</div>
    </div>
  );
};
