// extracted by mini-css-extract-plugin
export var accordion = "accordion-module--accordion--tNICd";
export var accordionOpen = "accordion-module--accordionOpen--xEY91";
export var accordionButtonIcon = "accordion-module--accordionButtonIcon--HJhBw";
export var accordionButton = "accordion-module--accordionButton--jMLQX";
export var accordionButtonColumn = "accordion-module--accordionButtonColumn--e9S1W";
export var accordionButtonColumnLeft = "accordion-module--accordionButtonColumnLeft--4X77H";
export var accordionButtonColumnCenter = "accordion-module--accordionButtonColumnCenter--B0F5e";
export var accordionButtonIconDesktop = "accordion-module--accordionButtonIconDesktop--sCN1V";
export var accordionButtonIconMobile = "accordion-module--accordionButtonIconMobile--yXt4p";
export var accordionInner = "accordion-module--accordionInner--9-zpU";