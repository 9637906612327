import Section from 'components/section';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import * as styles from './utilised-navigation.module.scss';

const UtilisedNavigation: React.FC<GatsbyTypes.UtilisedNavigationFragment> = ({
  backgroundColor,
  linksNode,
}) => {
  return (
    <Section sectionClassName={(styles as never)[`utilisedNavigationBG${backgroundColor}`]}>
      {linksNode?.childMdx?.body && (
        <div className={styles.utilisedNavigationLinks}>
          <MDXRenderer>{linksNode?.childMdx?.body}</MDXRenderer>
        </div>
      )}
    </Section>
  );
};

export default UtilisedNavigation;

export const query = graphql`
  fragment UtilisedNavigation on DatoCmsUtilisedNavigation {
    __typename
    id
    backgroundColor
    linksNode {
      childMdx {
        body
      }
    }
  }
`;
