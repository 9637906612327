import clsx from 'clsx';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql, Link } from 'gatsby';
import React from 'react';
import { camelize } from 'utils/functions';

import * as styles from './icon-item-group.module.scss';

type IconItemProps = GatsbyTypes.IconItemFragment & {
  direction: string;
  columns: string;
};

const LinkWrapper: React.FC<{ linkHref?: string }> = ({ linkHref, children }) => {
  if (linkHref)
    return (
      <Link className={styles.iconItemLink} to={linkHref}>
        {children}
      </Link>
    );

  return <>{children}</>;
};

const IconItem: React.FC<IconItemProps> = ({
  image,
  title,
  direction,
  hideInXs = false,
  columns,
  linkHref,
  imagePosition = 'left',
}) => {
  let columnConfig: ColumnResponsiveSizes = {};

  if (/row/i.test(direction)) {
    switch (columns) {
      case '2':
        columnConfig = {
          xl: 6,
          lg: 6,
          md: 6,
          sm: hideInXs ? 0 : 6,
          xs: hideInXs ? 0 : 6,
        };
        break;
      case '3':
        columnConfig = {
          xl: 4,
          lg: 4,
          md: 4,
          sm: hideInXs ? 0 : 4,
          xs: hideInXs ? 0 : 6,
        };
        break;
      case '4':
        columnConfig = {
          xl: 3,
          lg: 3,
          md: 3,
          sm: hideInXs ? 0 : 6,
          xs: hideInXs ? 0 : 6,
        };
        break;

      default:
        break;
    }
  }

  return (
    <Column
      className={clsx(
        'icon-item',
        styles.iconItem,
        (styles as never)[`iconItemImagePos${camelize(imagePosition)}`]
      )}
      {...columnConfig}
    >
      <LinkWrapper linkHref={linkHref}>
        {image && (
          <div className={styles.iconItemImageWrapper}>
            <Image className={styles.iconItemImage} {...image} />
          </div>
        )}
        <p className={styles.iconItemTitle} dangerouslySetInnerHTML={{ __html: title as string }} />
      </LinkWrapper>
    </Column>
  );
};

type IconItemGroupProps = GatsbyTypes.IconItemGroupFragment & {
  showTitle?: boolean;
  showSubTitle?: boolean;
  groupColumnCount: string;
  direction: string;
};

const IconItemGroup: React.FC<IconItemGroupProps> = ({
  title = '',
  subTitle = '',
  direction,
  showTitle = false,
  showSubTitle = false,
  groupColumnCount,
  iconItems,
}) => {
  const hasTitle = title?.length > 0;
  const hasSubTitle = subTitle?.length > 0;

  return (
    <div
      className={clsx(
        styles.group,
        { [styles.groupNoTitle]: !hasTitle && !hasSubTitle },
        (styles as never)[`groupDir${direction}`],
        (styles as never)[`groupCount${groupColumnCount}`]
      )}
    >
      {hasTitle ? (
        <h5
          className={clsx(styles.groupTitle, {
            [styles.withMargin]: !hasSubTitle,
            [styles.onlyTitle]: !showSubTitle,
          })}
        >
          {title}
        </h5>
      ) : showTitle ? (
        <h5
          className={clsx(styles.groupTitle, styles.groupHiddenText, {
            [styles.withMargin]: !hasSubTitle,
            [styles.onlyTitle]: !showSubTitle,
          })}
        >
          #
        </h5>
      ) : null}
      {hasSubTitle ? (
        <h5 className={styles.groupSubTitle}>{subTitle}</h5>
      ) : showSubTitle ? (
        <h5 className={clsx(styles.groupSubTitle, styles.groupHiddenText)}>#</h5>
      ) : null}
      <Section
        as={'div'}
        className={clsx(
          styles.itemsContainer,
          { [styles.hiddenTitle]: !hasTitle && !hasSubTitle },
          (styles as never)[`itemsContainerDir${direction}`],
          (styles as never)[`itemsContainerCount${groupColumnCount}`]
        )}
        innerGapX
        fullWidth
      >
        {iconItems?.map(
          (item) =>
            item && (
              <IconItem key={item.id} direction={direction} columns={groupColumnCount} {...item} />
            )
        )}
      </Section>
    </div>
  );
};

export default IconItemGroup;

export const query = graphql`
  fragment IconItemGroup on DatoCmsImageTextGroup {
    __typename
    id
    title
    subTitle
    iconItems: items {
      ...IconItem
    }
  }
  fragment IconItem on DatoCmsIconItem {
    __typename
    id
    imagePosition
    linkHref
    hideInXs
    image {
      ...Image
    }
    title
  }
`;
