import clsx from 'clsx';
import Column from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './testimonial.module.scss';

const Testimonial: React.FC<GatsbyTypes.TestimonialFragment> = ({
  image,
  backgroundColor,
  attribution,
  quote,
}) => {
  const classNames = clsx(styles.testimonial, (styles as never)[`testimonialBG${backgroundColor}`]);

  return (
    <Section className={classNames} fullWidth>
      <Image className={styles.testimonialImage} {...image} />
      <Section as={'div'} fullWidth={false} innerGapX className={styles.testimonialContent}>
        <Column
          className={styles.testimonialContentColumn}
          xl={{ width: 6, offset: 6 }}
          lg={{ width: 6, offset: 6 }}
          md={{ width: 8, offset: 4 }}
          sm={{ width: 8, offset: 4 }}
        >
          {attribution && <h5 dangerouslySetInnerHTML={{ __html: attribution }} />}
          {quote && <h1 dangerouslySetInnerHTML={{ __html: `<span>“</span>${quote}` }} />}
        </Column>
      </Section>
    </Section>
  );
};

export default Testimonial;

export const query = graphql`
  fragment Testimonial on DatoCmsTestimonial {
    __typename
    id
    backgroundColor
    attribution
    quote
    image {
      ...Image
    }
  }
`;
