// extracted by mini-css-extract-plugin
export var iconItem = "icon-item-group-module--iconItem--3HhDu";
export var iconItemLink = "icon-item-group-module--iconItemLink--vI3ee";
export var iconItemImage = "icon-item-group-module--iconItemImage--iyvfS";
export var iconItemImageWrapper = "icon-item-group-module--iconItemImageWrapper--AHsIo";
export var iconItemTitle = "icon-item-group-module--iconItemTitle--5b9QQ";
export var iconItemImagePosaboveText = "icon-item-group-module--iconItemImagePosaboveText--fpLxo";
export var group = "icon-item-group-module--group--cUA+D";
export var groupNoTitle = "icon-item-group-module--groupNoTitle--Ik9B0";
export var groupDirColumn = "icon-item-group-module--groupDirColumn--Ccmkv";
export var groupTitle = "icon-item-group-module--groupTitle--FxTK0";
export var withMargin = "icon-item-group-module--withMargin--jkuBW";
export var onlyTitle = "icon-item-group-module--onlyTitle--we4SW";
export var groupDirRow = "icon-item-group-module--groupDirRow--geDU1";
export var groupHiddenText = "icon-item-group-module--groupHiddenText--9e+AX";
export var groupSubTitle = "icon-item-group-module--groupSubTitle--jKpND";
export var groupCount1 = "icon-item-group-module--groupCount1--IyVLF";
export var itemsContainer = "icon-item-group-module--itemsContainer--zYvyN";
export var itemsContainerDirRow = "icon-item-group-module--itemsContainerDirRow--j0EWI";
export var itemsContainerCount1 = "icon-item-group-module--itemsContainerCount1--yaw1K";
export var itemsContainerCount2 = "icon-item-group-module--itemsContainerCount2--s5set";
export var itemsContainerCount3 = "icon-item-group-module--itemsContainerCount3--tzg+5";
export var itemsContainerCount4 = "icon-item-group-module--itemsContainerCount4--eDU7b";
export var itemsContainerDirColumn = "icon-item-group-module--itemsContainerDirColumn--xluff";
export var hiddenTitle = "icon-item-group-module--hiddenTitle--qieJF";