import clsx from 'clsx';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import * as styles from './title-and-image.module.scss';

const TitleAndImage: React.FC<GatsbyTypes.TitleAndImageFragment> = ({
  titleNode,
  backgroundColor,
  verticalCenter = false,
  rightImage,
  borderBottom,
  borderTop,
}) => {
  const leftColumnConfig: ColumnResponsiveSizes = {
    xl: 4,
    lg: 4,
    md: 4,
    sm: 4,
  };
  const rightColumnConfig: ColumnResponsiveSizes = {
    xl: 8,
    lg: 8,
    md: 8,
    sm: 8,
  };

  return (
    <Section
      className={clsx({
        [styles.titleAndImageBorderTop]: borderTop,
        [styles.titleAndImageBorderBottom]: borderBottom,
      })}
      sectionClassName={(styles as never)[`titleAndImageBG${backgroundColor}`]}
      innerGap
    >
      <Column
        {...leftColumnConfig}
        className={clsx({ [styles.titleAndImageCenterContent]: verticalCenter })}
      >
        {titleNode?.childMdx?.body && (
          <div>
            <MDXRenderer>{titleNode?.childMdx?.body}</MDXRenderer>
          </div>
        )}
      </Column>
      <Column
        {...rightColumnConfig}
        className={clsx({ [styles.titleAndImageCenterContent]: verticalCenter })}
      >
        <Image className={styles.titleAndImageImage} {...rightImage} />
      </Column>
    </Section>
  );
};

export default TitleAndImage;

export const query = graphql`
  fragment TitleAndImage on DatoCmsSubAndImage {
    __typename
    id
    titleNode {
      childMdx {
        body
      }
    }
    verticalCenter
    backgroundColor
    borderTop
    borderBottom
    rightImage {
      ...Image
    }
  }
`;
