import clsx from 'clsx';
import React, { ComponentPropsWithRef } from 'react';

import * as styles from './text-area.module.scss';

type TextAreaProps = {
  title: string;
  description?: string;
  errorMessage?: string;
};

const TextAreaBase = (
  {
    title,
    description = '',
    errorMessage,
    required,
    ...props
  }: TextAreaProps & Omit<ComponentPropsWithRef<'textarea'>, keyof TextAreaProps>,
  ref?: ComponentPropsWithRef<'textarea'>['ref']
) => {
  return (
    <div className={clsx(styles.textArea, { [styles.textAreaHasError]: errorMessage })}>
      <label htmlFor={props.name}>
        <h4>
          {title} {required && <span>*</span>}
        </h4>
        <p className={styles.textAreaDescription}>{description}</p>
      </label>
      <textarea ref={ref} id={props.name} rows={5} {...props} />
      {errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
    </div>
  );
};

const TextArea = React.forwardRef(TextAreaBase);
TextArea.displayName = 'TextArea';

export default TextArea as typeof TextAreaBase;
