// extracted by mini-css-extract-plugin
export var casesLink = "case-link-module--casesLink--uu3Ta";
export var sliderViewport = "case-link-module--sliderViewport--hDHXW";
export var sliderContainer = "case-link-module--sliderContainer--GXjli";
export var sliderControls = "case-link-module--sliderControls--753B5";
export var sliderControlsNavigation = "case-link-module--sliderControlsNavigation--pmNAK";
export var sliderControlsButton = "case-link-module--sliderControlsButton--AAktG";
export var sliderControlsContainer = "case-link-module--sliderControlsContainer--3UYPw";
export var sliderControlsDot = "case-link-module--sliderControlsDot--RxSc9";
export var sliderControlsDotActive = "case-link-module--sliderControlsDotActive--Zb+44";
export var sliderSlide = "case-link-module--sliderSlide--gJ4rw";