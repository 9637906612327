import { graphql, useStaticQuery } from 'gatsby';
import { HelmetDatoCms } from 'gatsby-source-datocms';
import DesktopMenu from './desktop-menu';
import LayoutContext from 'layouts/reducer';
import MobileMenu from './mobile-menu';
import React from 'react';

import * as styles from './header.module.scss';

const Header: React.FC = () => {
  const { state } = React.useContext(LayoutContext);
  const { datoCmsHeaderMenu, datoCmsSite } = useStaticQuery<GatsbyTypes.HeaderQueryQuery>(graphql`
    query HeaderQuery {
      datoCmsHeaderMenu {
        links {
          ...Link
        }
      }
      datoCmsSite {
        faviconMetaTags {
          tags
        }
      }
    }
  `);

  return (
    <header className={styles.header}>
      <HelmetDatoCms seo={datoCmsSite?.faviconMetaTags} />
      {state.isMedium && <DesktopMenu datoCmsHeaderMenu={datoCmsHeaderMenu} />}
      {!state.isMedium && <MobileMenu datoCmsHeaderMenu={datoCmsHeaderMenu} />}
    </header>
  );
};

export default Header;
