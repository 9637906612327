// extracted by mini-css-extract-plugin
export var bevel = "bevel-module--bevel--V3fX6";
export var bevelBgwhite = "bevel-module--bevelBgwhite--DqP61";
export var bevelBgmilk = "bevel-module--bevelBgmilk--mz6cJ";
export var bevelBgflamenco = "bevel-module--bevelBgflamenco--o0X5U";
export var bevelBgbenzo = "bevel-module--bevelBgbenzo--BMuYg";
export var bevelBgpinky = "bevel-module--bevelBgpinky--q9bAr";
export var bevelBgblacky = "bevel-module--bevelBgblacky--Dl8pu";
export var bevelPosbottomLeft = "bevel-module--bevelPosbottomLeft--DS7Q6";
export var bevelPosbottomRight = "bevel-module--bevelPosbottomRight--FRz1k";
export var bevelPostopLeft = "bevel-module--bevelPostopLeft--AfD-C";
export var bevelPostopRight = "bevel-module--bevelPostopRight--Nyek9";
export var bevelSizesmall = "bevel-module--bevelSizesmall--TTTuE";
export var bevelSizelarge = "bevel-module--bevelSizelarge--46I9v";