import clsx from 'clsx';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { useEmblaCarousel } from 'embla-carousel/react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import LayoutContext from 'layouts/reducer';
import React from 'react';

import * as styles from './card-block.module.scss';

const Card: React.FC<GatsbyTypes.CardFragment> = ({ image, title, textNode }) => {
  return (
    <>
      <Column sm={4}>
        <Image className={styles.cardImage} {...image} />
        {title && <h3 className={styles.hiddenSmall} dangerouslySetInnerHTML={{ __html: title }} />}
      </Column>
      <Column className={styles.cardContent} sm={8}>
        {title && <h3 className={styles.showSmall} dangerouslySetInnerHTML={{ __html: title }} />}
        {textNode?.childMdx?.body && (
          <div>
            <MDXRenderer>{textNode?.childMdx?.body}</MDXRenderer>
          </div>
        )}
      </Column>
    </>
  );
};

const CardBlock: React.FC<GatsbyTypes.CardBlockFragment> = ({ cards = [] }) => {
  const { state } = React.useContext(LayoutContext);
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [sliderRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    dragFree: false,
    align: 'start',
    speed: 20,
    draggable: !state.isSmall,
  });
  const scrollTo = React.useCallback((index) => embla && embla.scrollTo(index), [embla]);

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
  }, [embla, setSelectedIndex]);

  React.useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const cardColumn: ColumnResponsiveSizes = {
    xl: 4,
    lg: 4,
    md: 4,
    xs: 10,
  };

  return (
    <Section sectionClassName={styles.cardBlock} innerGapX>
      <div ref={sliderRef} className={styles.sliderViewport}>
        <div className={styles.sliderContainer}>
          {cards.map(
            (card) =>
              card && (
                <Column
                  key={card.id}
                  className={clsx(styles.card, styles.sliderSlide)}
                  {...cardColumn}
                >
                  <Card {...card} />
                </Column>
              )
          )}
        </div>
      </div>
      <div className={styles.sliderDots}>
        {scrollSnaps.map(
          (_, index) =>
            cards[index] && (
              <div
                key={`${cards[index]?.id}-dot`}
                className={clsx(styles.sliderDotsItem, {
                  [styles.sliderDotsItemActive]: selectedIndex === index,
                })}
                onClick={() => scrollTo(index)}
              >
                <div />
              </div>
            )
        )}
      </div>
    </Section>
  );
};

export default CardBlock;

export const query = graphql`
  fragment Card on DatoCmsCard {
    __typename
    id
    image {
      isImage
      mimeType
      gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "1", fit: "crop" })
      alt
      url
    }
    title
    textNode {
      childMdx {
        body
      }
    }
  }
  fragment CardBlock on DatoCmsCardBlock {
    __typename
    id
    cards {
      ...Card
    }
  }
`;
