import clsx from 'clsx';
import BevelForm from 'images/svg/bevel.inline.svg';
import React from 'react';
import { ThemeColor } from 'types/global';

import * as styles from './bevel.module.scss';

export type BevelPosition = 'topLeft' | 'topRight' | 'bottomLeft' | 'bottomRight';

type BevelProps = {
  size: 'small' | 'large';
  position: BevelPosition;
  color: ThemeColor;
  className?: string;
};

const Bevel: React.FC<BevelProps> = ({ size, position, color, className }) => {
  return (
    <BevelForm
      className={clsx(
        'bevel',
        className,
        styles.bevel,
        (styles as never)[`bevelSize${size}`],
        (styles as never)[`bevelPos${position}`],
        (styles as never)[`bevelBg${color}`]
      )}
    />
  );
};

export default Bevel;
