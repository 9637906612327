import Section from 'components/section';
import Column, { ColumnResponsiveSizes } from 'components/column';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './diagram.module.scss';
import ReactVisibilitySensor from 'react-visibility-sensor';
import clsx from 'clsx';

const DiagramItem: React.FC<GatsbyTypes.DiagramItemFragment> = ({ percentage = 0, title }) => {
  const [isVisible, setIsVisible] = React.useState<boolean>(false);
  const ref = React.useRef<SVGCircleElement>();

  React.useEffect(() => {
    if (!ref.current) return;

    const circumference = ref.current.r.baseVal.value * 2 * Math.PI;

    ref.current.style.strokeDasharray = `${circumference} ${circumference}`;
    const offset = circumference - (0 / 100) * circumference;

    ref.current.style.strokeDashoffset = `${offset}`;
  }, []);

  React.useEffect(() => {
    if (!isVisible || !ref.current) return;

    const circumference = ref.current.r.baseVal.value * 2 * Math.PI;
    const offset = circumference - ((percentage > 100 ? 100 : percentage) / 100) * circumference;
    ref.current.style.strokeDashoffset = `${offset}`;
  }, [isVisible, percentage]);

  return (
    <ReactVisibilitySensor
      active={!isVisible}
      onChange={(vis) => vis && setIsVisible(true)}
      delayedCall
      partialVisibility
      offset={{ bottom: 300 }}
    >
      <div className={styles.item}>
        <svg
          viewBox="0 0 100 100"
          className={clsx(styles.ring, { [styles.ringVisible]: isVisible })}
        >
          <circle fill="white" strokeWidth="0" r="44" cx="50" cy="50" />
          <circle
            ref={ref as React.LegacyRef<SVGCircleElement>}
            strokeWidth="6"
            fill="transparent"
            transform="rotate(-90,50,50)"
            r="47"
            cx="50"
            cy="50"
          />
          <text x="53%" y="50%" dominantBaseline="middle" textAnchor="middle">
            {`${percentage}%`}
          </text>
        </svg>
        {title && <p dangerouslySetInnerHTML={{ __html: title }} />}
      </div>
    </ReactVisibilitySensor>
  );
};

const Diagram: React.FC<GatsbyTypes.DiagramFragment> = ({ items = [] }) => {
  const fourItems = items.length >= 4;
  const twoItems = items.length === 2;
  let columnConfig: ColumnResponsiveSizes = {
    xl: fourItems ? 3 : 4,
    lg: fourItems ? 3 : 4,
    md: fourItems ? 3 : 4,
    sm: fourItems ? 6 : 4,
    xs: 6,
  };

  if (twoItems) {
    columnConfig = {
      xl: 6,
      lg: 6,
      md: 6,
      sm: 6,
      xs: 6,
    };
  }

  const lastItem = { ...columnConfig };

  if (!fourItems) {
    lastItem.xs = { width: 6, offset: 3 };
  }

  return (
    <Section className={styles.diagram} innerGapX>
      {items?.map(
        (diagramItem, index) =>
          diagramItem && (
            <Column
              key={diagramItem.id}
              className={styles.itemWrapper}
              {...(index === items.length - 1 ? lastItem : columnConfig)}
            >
              <DiagramItem {...diagramItem} />
            </Column>
          )
      )}
    </Section>
  );
};

export default Diagram;

export const query = graphql`
  fragment DiagramItem on DatoCmsDiagramItem {
    __typename
    id
    title
    percentage
  }
  fragment Diagram on DatoCmsDiagram {
    __typename
    id
    items {
      ...DiagramItem
    }
  }
`;
