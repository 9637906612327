// extracted by mini-css-extract-plugin
export var caseBlock = "case-block-module--caseBlock--evPYL";
export var caseBlockImage = "case-block-module--caseBlockImage--qYzFr";
export var caseBlockNoTitles = "case-block-module--caseBlockNoTitles--T27dg";
export var caseBlockContent = "case-block-module--caseBlockContent--vb2BF";
export var caseBlockBGMilk = "case-block-module--caseBlockBGMilk--CZvW6";
export var caseBlockBGWhite = "case-block-module--caseBlockBGWhite--F7zI4";
export var caseBlockBorderTop = "case-block-module--caseBlockBorderTop--Nfesk";
export var caseBlockBorderBottom = "case-block-module--caseBlockBorderBottom--OpuCk";
export var titleTop = "case-block-module--titleTop--VLPD+";
export var titleMain = "case-block-module--titleMain--kAqPC";
export var titleSub = "case-block-module--titleSub--laS25";