// extracted by mini-css-extract-plugin
export var cardBlock = "card-block-module--cardBlock--s3HVu";
export var sliderViewport = "card-block-module--sliderViewport--7-H4p";
export var sliderContainer = "card-block-module--sliderContainer--W3wOb";
export var sliderDots = "card-block-module--sliderDots--v6LuQ";
export var sliderDotsItem = "card-block-module--sliderDotsItem--pRfAC";
export var sliderDotsItemActive = "card-block-module--sliderDotsItemActive--O4-9n";
export var sliderSlide = "card-block-module--sliderSlide--jjA-l";
export var card = "card-block-module--card--F4dlL";
export var hiddenSmall = "card-block-module--hiddenSmall--TA26f";
export var showSmall = "card-block-module--showSmall--fsf+S";
export var cardContent = "card-block-module--cardContent--RAhsO";
export var cardText = "card-block-module--cardText--kHJ1p";
export var cardImage = "card-block-module--cardImage--lCdZx";