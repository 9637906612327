import Column from 'components/column';
import Icon from 'images/svg/icon.inline.svg';
import Logo from 'images/svg/logo-white.inline.svg';
import Link, { LinkProps } from 'components/link';
import Headroom from 'react-headroom';
import React from 'react';
import clsx from 'clsx';
import Section from 'components/section';

import * as styles from './header.module.scss';

const DesktopMenu: React.FC<Pick<GatsbyTypes.HeaderQueryQuery, 'datoCmsHeaderMenu'>> = ({
  datoCmsHeaderMenu,
}) => {
  return (
    <Headroom className={styles.headroom} upTolerance={0} disableInlineStyles>
      <Section as={'div'} innerGapX sectionClassName={styles.headerDesktopSection}>
        <Column className={styles.headerColumnLogo} xl={1} lg={1} md={1}>
          <Link internalLink={{ model: { apiKey: 'start_page' } }}>
            <Icon />
          </Link>
        </Column>
        <Column className={clsx(styles.headerColumn, styles.headerColumnLeft)} xl={3} lg={3} md={2}>
          <Link className={styles.headerLink} internalLink={{ model: { apiKey: 'start_page' } }}>
            <Logo />
          </Link>
        </Column>
        <Column className={styles.headerColumn} xl={8} lg={8} md={9}>
          {datoCmsHeaderMenu?.links?.map(
            (link) =>
              link && (
                <Link
                  className={styles.headerLink}
                  activeClassName={styles.headerLinkActive}
                  key={link.id}
                  {...(link as LinkProps)}
                >
                  {link.text}
                </Link>
              )
          )}
        </Column>
      </Section>
    </Headroom>
  );
};

export default DesktopMenu;
