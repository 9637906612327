import { MDXProvider } from '@mdx-js/react';
import React from 'react';
import 'styles/global.scss';
import 'styles/typography.scss';
import * as Icons from 'components/icon';
import LayoutContext, { layoutReducer, defaultState as layoutDefault } from './reducer';
import Header from 'components/header';
import AccordionContext, {
  accordionReducer,
  defaultState as accordionDefault,
} from 'sections/accordion/reducer';
import Footer from 'components/footer';

type Props = {
  children: React.ReactNode;
};

const isBrowser = typeof window !== 'undefined';

const MainLayout: React.FC<Props> = ({ children }) => {
  const [state, dispatch] = React.useReducer(layoutReducer, layoutDefault);

  const contextValue = React.useMemo(
    () => ({
      state,
      dispatch,
    }),
    [state, dispatch]
  );

  const [accordionState, accordionDispatch] = React.useReducer(accordionReducer, accordionDefault);

  const accordionContextValue = React.useMemo(
    () => ({
      state: accordionState,
      dispatch: accordionDispatch,
    }),
    [accordionState, accordionDispatch]
  );

  // Resolve viewport height
  const setAppHeight = React.useCallback(() => {
    if (!isBrowser) return;

    const doc = document.documentElement;
    doc.style.setProperty('--vh', `${parseFloat((window.innerHeight * 0.01).toFixed(2))}px`);
  }, []);

  const setAppWidth = React.useCallback(() => {
    if (!isBrowser) return;

    dispatch({
      type: 'update',
      width: window.innerWidth,
    });
  }, []);

  React.useEffect(() => {
    if (!isBrowser) return;
    setAppWidth();
    setAppHeight();

    if (state.isSmall) {
      window.addEventListener('resize', setAppHeight);
    } else {
      window.removeEventListener('resize', setAppHeight);
    }

    window.addEventListener('resize', setAppWidth);

    return () => {
      window.removeEventListener('resize', setAppHeight);
      window.removeEventListener('resize', setAppWidth);
    };
  }, [setAppHeight, setAppWidth, state.isSmall]);

  return (
    <LayoutContext.Provider value={contextValue}>
      <AccordionContext.Provider value={accordionContextValue}>
        <MDXProvider components={Icons}>
          <Header />
          {children}
          <Footer />
        </MDXProvider>
      </AccordionContext.Provider>
    </LayoutContext.Provider>
  );
};

export default MainLayout;
