import clsx from 'clsx';
import Column from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import * as styles from './case-block.module.scss';

const CaseBlock: React.FC<GatsbyTypes.CaseBlockFragment> = ({
  image,
  topTitle,
  title,
  subTitle,
  textNode,
  borderTop,
  borderBottom,
  backgroundColor = 'Milk',
}) => {
  const classNames = clsx(styles.caseBlock, (styles as never)[`caseBlockBG${backgroundColor}`], {
    [styles.caseBlockBorderTop]: borderTop,
    [styles.caseBlockBorderBottom]: borderBottom,
    [styles.caseBlockNoTitles]: !topTitle && !title && !subTitle,
  });

  return (
    <Section className={classNames} innerGapX>
      <Column className={styles.caseBlockImage} xl={4} lg={4} md={4} sm={4}>
        <Image {...image} />
      </Column>
      <Column className={styles.caseBlockContent} xl={8} lg={8} md={8} sm={8}>
        <div>
          {topTitle && (
            <h5 className={styles.titleTop} dangerouslySetInnerHTML={{ __html: topTitle }} />
          )}
          {title && <h2 className={styles.titleMain} dangerouslySetInnerHTML={{ __html: title }} />}
          {subTitle && (
            <h2 className={styles.titleSub} dangerouslySetInnerHTML={{ __html: subTitle }} />
          )}
        </div>
        {textNode?.childMdx?.body && (
          <div>
            <MDXRenderer>{textNode?.childMdx?.body}</MDXRenderer>
          </div>
        )}
      </Column>
    </Section>
  );
};

export default CaseBlock;

export const query = graphql`
  fragment CaseBlock on DatoCmsCaseBlock {
    __typename
    id
    image {
      isImage
      mimeType
      gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "1", fit: "crop" })
      alt
      url
    }
    topTitle
    title
    subTitle
    textNode {
      childMdx {
        body
      }
    }
    borderTop
    borderBottom
    backgroundColor
  }
`;
