import Bevel from 'components/bevel';
import Image from 'components/image';
import Section from 'components/section';
import Video from 'components/video';
import { graphql } from 'gatsby';
import React from 'react';
import { ThemeColor } from 'types/global';

import * as styles from './hero.module.scss';

type OverlayComponentProps = {
  title: string;
};

const OverlayComponent: React.FC<OverlayComponentProps> = ({ title }) => {
  return (
    <div className={styles.heroOverlay}>
      <h1 dangerouslySetInnerHTML={{ __html: title }} />
    </div>
  );
};

type VideoComponentProps = {
  url: string;
  posterUrl?: string;
  image?: GatsbyTypes.ImageFragment;
  title?: string;
};

const VideoComponent: React.FC<VideoComponentProps> = ({ url, posterUrl, image, title }) => {
  return (
    <Video
      url={url}
      posterUrl={posterUrl}
      renderPoster={() => <ImageComponent title={title} image={image} />}
    />
  );
};

type ImageComponentProps = {
  image?: GatsbyTypes.ImageFragment;
  title?: string;
};

const ImageComponent: React.FC<ImageComponentProps> = ({ image, title }) => {
  return (
    <>
      {title && <OverlayComponent title={title} />}
      <Image className={styles.heroImage} {...image} />
    </>
  );
};

const Hero: React.FC<GatsbyTypes.HeroFragment> = ({ bevelColor = 'milk', ...props }) => {
  return (
    <Section className={styles.hero} fullWidth>
      {props.video?.url ? (
        <VideoComponent
          url={props.video.url}
          posterUrl={props.poster?.url}
          image={props.image}
          title={props.title}
        />
      ) : (
        <ImageComponent image={props.image} title={props.title} />
      )}
      <Bevel size={'large'} color={bevelColor as ThemeColor} position={'bottomLeft'} />
    </Section>
  );
};

export default Hero;

export const query = graphql`
  fragment Hero on DatoCmsHero {
    __typename
    id
    bevelColor
    title
    image {
      ...Image
    }
    poster: image {
      url(imgixParams: { h: "900" })
    }
    video {
      url
    }
  }
`;
