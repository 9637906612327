import clsx from 'clsx';
import React from 'react';
import ReactPlayer from 'react-player';
import PlayIcon from 'images/svg/play.inline.svg';

import * as styles from './video.module.scss';

type VideoComponentProps = {
  url: string;
  className?: string;
  posterUrl?: string;
  renderPoster?: (playing: boolean) => JSX.Element;
  onLoad?: () => void;
};

const Video: React.FC<VideoComponentProps> = ({
  url,
  className,
  posterUrl,
  renderPoster,
  onLoad,
}) => {
  const [playing, setPlaying] = React.useState<boolean>(false);

  return (
    <div className={clsx(styles.videoWrapper, { [styles.videoPlaying]: playing })}>
      {!playing && <PlayIcon className={styles.videoPlayButton} onClick={() => setPlaying(true)} />}
      {renderPoster && <div className={styles.videoPosterComponent}>{renderPoster(playing)}</div>}
      <ReactPlayer
        className={clsx(styles.video, className)}
        pip={false}
        url={url}
        controls={playing}
        playing={playing}
        onEnded={() => setPlaying(false)}
        config={{
          file: {
            attributes: { poster: posterUrl ? posterUrl : '' },
          },
        }}
        onReady={() => {
          if (onLoad) onLoad();
          // IE11 Polyfill for object-fit on video element
          const vids = Array.from(document.querySelectorAll(`.${styles.video} video`));
          if (vids.length > 0) {
            vids.forEach((vid) => {
              vid.setAttribute('data-object-fit', '');
            });
            window.objectFitPolyfill();
          }
        }}
        width={'100%'}
        height={'100%'}
      />
    </div>
  );
};

export default Video;
