import React from 'react';
import { useStaticQuery, graphql, Link as GLink, GatsbyLinkProps } from 'gatsby';

import * as styles from './link.module.scss';

interface LinkBasic {
  id?: string;
}
interface LinkWithSlug extends LinkBasic {
  slug: string;
  model?: {
    apiKey: string;
  };
}
interface LinkWithModel extends LinkBasic {
  slug?: string;
  model: {
    apiKey: string;
  };
}

export type LinkType = LinkWithSlug | LinkWithModel | (LinkWithSlug & LinkWithModel);

export type LinkProps = {
  as?: 'GLink' | 'ALink';
  className?: string;
  state?: unknown;
  activeClassName?: string;
  internalLink: LinkType;
  title?: string;
  target?: string;
  externalUrl?: string;
  onClick?: React.MouseEventHandler<HTMLElement>;
};

type GlobalSlug = {
  model: string;
  slug: string;
};

export const EmailLink: React.FC<{ email: string }> = ({ email }) => {
  const openEmail = (address: string) => {
    if (!window) return;

    window.location.href = `mailto:${address}`;
  };

  return (
    <span className={styles.linkEmail} onClick={() => openEmail(email)}>
      {email.split('').reverse().join('')}
    </span>
  );
};

const getSlug = (link: LinkType, slugs: GlobalSlug[]): string => {
  const mainSlug = slugs.find((s) => link.model && s.model === link.model.apiKey);

  if (!mainSlug && link.slug && /^\//.test(link.slug)) {
    return link.slug;
  }
  if (!mainSlug && link.slug) {
    return `/${link.slug}`;
  }

  if (!link.slug) {
    return mainSlug?.slug ?? '';
  }

  return `${mainSlug?.slug}/${link.slug}`;
};

const Link: React.FC<LinkProps> = ({
  as = 'GLink',
  externalUrl = '',
  activeClassName = '',
  onClick,
  state,
  target = '_self',
  className,
  ...props
}) => {
  const { site } = useStaticQuery<GatsbyTypes.GlobalSlugsQuery>(graphql`
    query GlobalSlugs {
      site {
        siteMetadata {
          slugs {
            model
            slug
          }
        }
      }
    }
  `);
  let linkProps: Omit<GatsbyLinkProps<unknown>, 'ref'>;

  if (as === 'ALink') {
    return (
      <a
        href={getSlug(props.internalLink, site?.siteMetadata?.slugs as GlobalSlug[])}
        target="_blank"
        className={className}
        rel="noopener noreferrer"
      >
        {props.children}
      </a>
    );
  } else if (externalUrl.length > 0) {
    linkProps = {
      to: externalUrl,
      activeClassName,
      className,
      onClick,
      state,
      target: '_blank',
      rel: 'noreferrer',
    };
  } else {
    linkProps = {
      to: getSlug(props.internalLink, site?.siteMetadata?.slugs as GlobalSlug[]),
      activeClassName,
      onClick,
      className,
      state,
      target,
      rel: target !== '_self' ? 'noreferrer' : '',
    };
  }

  return <GLink {...linkProps}>{props.children}</GLink>;
};

export default Link;

export const query = graphql`
  fragment LinkCollection on DatoCmsUnionForDatoCmsLinkInternalLink {
    ... on DatoCmsStartpage {
      id
      model {
        apiKey
      }
    }
    ... on DatoCmsCaseArchive {
      id
      model {
        apiKey
      }
    }
    ... on DatoCmsPage {
      id
      slug
      model {
        apiKey
      }
    }
    ... on DatoCmsCase {
      id
      slug
      model {
        apiKey
      }
    }
  }

  fragment Link on DatoCmsLink {
    id
    externalUrl
    text
    target
    internalLink {
      ...LinkCollection
    }
  }
`;
