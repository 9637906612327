// extracted by mini-css-extract-plugin
export var dropzone = "dropzone-module--dropzone--37Wqo";
export var dropzoneTitle = "dropzone-module--dropzoneTitle--KSnJe";
export var dropzoneDescription = "dropzone-module--dropzoneDescription--gnDIQ";
export var dropzoneWrapper = "dropzone-module--dropzoneWrapper--jr79u";
export var dropzoneZone = "dropzone-module--dropzoneZone--9mcKU";
export var dropzoneZoneHidden = "dropzone-module--dropzoneZoneHidden--yn8Gi";
export var dropzoneZoneSelectedFiles = "dropzone-module--dropzoneZoneSelectedFiles---1G-2";
export var dropzoneDropIcon = "dropzone-module--dropzoneDropIcon--l+UHQ";
export var dropzoneFilesContainer = "dropzone-module--dropzoneFilesContainer--tvhdN";
export var dropzoneFilesContainerTitle = "dropzone-module--dropzoneFilesContainerTitle--bX56z";
export var dropzoneFile = "dropzone-module--dropzoneFile--oouDA";
export var dropzoneFileSize = "dropzone-module--dropzoneFileSize--eIZhx";
export var dropzoneFilePreview = "dropzone-module--dropzoneFilePreview--5MNQU";
export var dropzoneBtnRemove = "dropzone-module--dropzoneBtnRemove--DBDGL";