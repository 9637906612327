import clsx from 'clsx';
import Bevel, { BevelPosition } from 'components/bevel';
import { graphql } from 'gatsby';
import React from 'react';
import { ThemeColor } from 'types/global';
import { camelize } from 'utils/functions';

import * as styles from './spacing.module.scss';

const Spacing: React.FC<
  Partial<Pick<GatsbyTypes.SpacingFragment, 'backgroundColor' | 'size' | 'bevel' | 'bevelColor'>>
> = ({ backgroundColor, size = '', bevel, bevelColor }) => {
  const classNames = clsx(
    styles.spacing,
    (styles as never)[`spacingBG${backgroundColor}`],
    (styles as never)[`spacingSize${size}`]
  );

  return (
    <section className={classNames}>
      {bevel && (
        <Bevel
          size={/large|medium/i.test(size) ? 'large' : 'small'}
          position={camelize(bevel) as BevelPosition}
          color={bevelColor as ThemeColor}
        />
      )}
    </section>
  );
};

export default Spacing;

export const query = graphql`
  fragment Spacing on DatoCmsSpacing {
    __typename
    id
    backgroundColor
    bevel
    bevelColor
    size
  }
`;
