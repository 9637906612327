import Bevel from 'components/bevel';
import Column from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import * as styles from './big-hero.module.scss';

const BigHero: React.FC<GatsbyTypes.BigHeroFragment> = (props) => {
  return (
    <>
      <Section className={styles.bigHero} fullWidth>
        <Image className={styles.bigHeroImage} {...props.mainImage} />
        <Bevel size={'large'} color={'white'} position={'bottomRight'} />
      </Section>
      <Section className={styles.floatingSection}>
        <Column>
          <Column className={styles.contentColumnTitle} xl={6} lg={6} md={6}>
            <div>
              {props.titleNode?.childMdx?.body && (
                <MDXRenderer>{props.titleNode.childMdx?.body}</MDXRenderer>
              )}
            </div>
          </Column>
        </Column>
      </Section>
      <Section className={styles.content}>
        <Column xl={6} lg={6} md={6}>
          {props.textNode?.childMdx?.body && (
            <div className={styles.contentColumnText}>
              <MDXRenderer>{props.textNode?.childMdx?.body}</MDXRenderer>
              <Bevel size={'large'} color={'milk'} position={'bottomLeft'} />
            </div>
          )}
        </Column>
        <Column xl={6} lg={6} md={6}>
          <div className={styles.contentColumnImage}>
            <div>
              <Image className={styles.contentColumnImageItem} {...props.secondaryImage} />
              <Bevel
                className={styles.imageBevel}
                size={'small'}
                color={'white'}
                position={'bottomLeft'}
              />
            </div>
            <Bevel
              className={styles.milkBevel}
              size={'small'}
              color={'milk'}
              position={'bottomLeft'}
            />
          </div>
        </Column>
        <Bevel
          className={styles.mobileBevel}
          size={'small'}
          color={'milk'}
          position={'bottomLeft'}
        />
      </Section>
    </>
  );
};

export default BigHero;

export const query = graphql`
  fragment BigHero on DatoCmsBigHero {
    __typename
    id
    titleNode {
      childMdx {
        body
      }
    }
    mainImage {
      ...Image
    }
    secondaryImage {
      isImage
      mimeType
      gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "2", fit: "crop" })
      alt
      url
    }
    textNode {
      childMdx {
        body
      }
    }
  }
`;
