import clsx from 'clsx';
import Column, { ColumnResponsiveSizes } from 'components/column';
import IconItemGroup from 'components/icon-item-group';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './icon-text-list.module.scss';

const IconTextList: React.FC<GatsbyTypes.IconTextListFragment> = ({
  iconGroups = [],
  columns = '3',
  direction = 'Column',
  backgroundColor,
}) => {
  const className = clsx((styles as never)[`iconTextListBG${backgroundColor}`]);

  let groupColumn: ColumnResponsiveSizes = {};

  if (/column/i.test(direction)) {
    switch (columns) {
      case '2':
        groupColumn = {
          xl: 6,
          lg: 6,
          md: 6,
          sm: 6,
        };
        break;
      case '3':
        groupColumn = {
          xl: 4,
          lg: 4,
          md: 4,
          sm: 4,
        };
        break;
      case '4':
        groupColumn = {
          xl: 3,
          lg: 3,
          md: 3,
          sm: 6,
        };
        break;

      default:
        break;
    }
  }

  const showTitle = !!iconGroups.find((group) => (group?.title as string).length > 1);
  const showSubTitle = !!iconGroups.find((group) => (group?.subTitle as string).length > 1);

  return (
    <Section className={className} innerGapX>
      {iconGroups?.map(
        (group) =>
          group && (
            <Column key={group.id} className={styles.iconTextListGroup} {...groupColumn}>
              <IconItemGroup
                groupColumnCount={columns}
                direction={direction}
                showTitle={showTitle}
                showSubTitle={showSubTitle}
                {...group}
              />
            </Column>
          )
      )}
    </Section>
  );
};

export default IconTextList;

export const query = graphql`
  fragment IconTextList on DatoCmsIconTextList {
    __typename
    id
    columns
    direction
    backgroundColor
    iconGroups: groups {
      ...IconItemGroup
    }
  }
`;
