// extracted by mini-css-extract-plugin
export var bigHero = "big-hero-module--bigHero--FviNo";
export var bigHeroImage = "big-hero-module--bigHeroImage--2v8QD";
export var floatingSection = "big-hero-module--floatingSection--uhfu6";
export var content = "big-hero-module--content--im3-2";
export var mobileBevel = "big-hero-module--mobileBevel--Cqpz4";
export var contentColumnText = "big-hero-module--contentColumnText--pgO6w";
export var contentColumnImage = "big-hero-module--contentColumnImage--I-K8I";
export var imageBevel = "big-hero-module--imageBevel--tCuKT";
export var milkBevel = "big-hero-module--milkBevel--X2pZh";
export var contentColumnImageItem = "big-hero-module--contentColumnImageItem--Z5DCd";
export var contentColumnTitle = "big-hero-module--contentColumnTitle--Ux2kH";