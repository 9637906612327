import { DropzoneOptions, useDropzone } from 'react-dropzone';
import React from 'react';
import { humanFileSize } from 'utils/functions';
import CloseIcon from 'images/svg/close-icon.inline.svg';
import FiledropIcon from 'images/svg/filedrop.inline.svg';

import * as styles from './dropzone.module.scss';
import clsx from 'clsx';

type DropzoneProps = DropzoneOptions & {
  title?: string;
  description?: string;
  errorMessage?: string;
  className?: string;
  name?: string;
};

const Dropzone: React.FC<DropzoneProps> = ({
  title,
  className,
  description,
  errorMessage,
  name,
  onDrop,
  multiple = false,
  ...options
}) => {
  const [files, setFiles] = React.useState<File[]>([]);

  const handleDrop = React.useCallback(
    (file, fileRejection, event) => {
      if (multiple) {
        setFiles((files) => [...files, ...file]);
        if (onDrop) onDrop([...files, ...file], fileRejection, event);
      } else {
        setFiles(file);
        if (onDrop) onDrop(file, fileRejection, event);
      }
    },
    [onDrop, files, multiple]
  );

  const { getRootProps, getInputProps } = useDropzone({
    onDrop: handleDrop,
    multiple,
    ...options,
    validator: (file) => {
      const existing = files.find((f) => JSON.stringify(f) === JSON.stringify(file));

      if (!existing) return null;

      return { code: 'duplicate', message: 'The file has already been selected' };
    },
  });

  const handleRemoveFile = React.useCallback((file: File) => {
    setFiles((files) => files.filter((f) => JSON.stringify(f) !== JSON.stringify(file)));
  }, []);

  const showDropZone = (files.length <= 0 && !multiple) || multiple;

  return (
    <div className={clsx(className, styles.dropzone)}>
      {<h4 className={styles.dropzoneTitle}>{title}</h4>}
      {description && <p className={styles.dropzoneDescription}>{description}</p>}
      <div className={styles.dropzoneWrapper}>
        <div
          className={clsx(styles.dropzoneZone, {
            [styles.dropzoneZoneSelectedFiles]: files.length > 0,
            [styles.dropzoneZoneHidden]: !showDropZone,
          })}
          {...getRootProps()}
        >
          <input name={name} {...getInputProps()} />
          <FiledropIcon className={styles.dropzoneDropIcon} />
          <h5>Drag {multiple ? 'files' : 'file'} here or click to upload </h5>
          {options.maxSize && (
            <small>
              ({`${humanFileSize(options.maxSize)} size or 30 seconds request time limit`})
            </small>
          )}
        </div>
        {files.length > 0 && (
          <div className={styles.dropzoneFilesContainer}>
            <div className={styles.dropzoneFilesContainerTitle}>
              <span>Selected {multiple ? 'files' : 'file'}</span>
            </div>
            {files.map((file) => (
              <div key={file.name} className={styles.dropzoneFile}>
                <button
                  className={styles.dropzoneBtnRemove}
                  title={'Remove file from list'}
                  onClick={() => handleRemoveFile(file)}
                >
                  <CloseIcon />
                </button>
                <small className={styles.dropzoneFileSize}>
                  {humanFileSize(file.size, true, 1)}
                </small>
                <span>{file.name}</span>
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default Dropzone;
