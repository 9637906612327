import React, { Dispatch, Reducer, ReducerAction, ReducerState } from 'react';

type accordionState = {
  id: string;
  openTabIndex: number;
};

type State = {
  accordionStates: accordionState[];
};

type Action = { type: 'toggle'; accordionId: string; tabIndex: number };

export const defaultState: State = {
  accordionStates: [],
};

export function accordionReducer(state: State, action: Action): State {
  const otherStates = state.accordionStates.filter((s) => s.id !== action.accordionId);

  switch (action.type) {
    case 'toggle':
      return {
        accordionStates: [
          ...otherStates,
          {
            id: action.accordionId,
            openTabIndex: action.tabIndex,
          },
        ],
      };
  }
}

const AccordionContext = React.createContext<{
  state: ReducerState<Reducer<State, Action>>;
  dispatch: Dispatch<ReducerAction<Reducer<State, Action>>>;
}>({
  state: defaultState,
  dispatch: () => {
    return;
  },
});

export default AccordionContext;
