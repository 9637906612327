import clsx from 'clsx';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Image from 'components/image';
import Section from 'components/section';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';

import * as styles from './two-text-image-column.module.scss';

const TwoTextImageColumn: React.FC<GatsbyTypes.TwoTextImageColumnFragment> = ({
  borderTop = false,
  borderBottom = false,
  title,
  leftImage,
  leftTextNode,
  rightImage,
  fillEmptyImageHeight = false,
  rightTextNode,
  backgroundColor,
  leftColumnWidth = '',
}) => {
  const oneThird = /third/i.test(leftColumnWidth);
  const leftColumnConfig: ColumnResponsiveSizes = {
    xl: oneThird ? 4 : 6,
    lg: oneThird ? 4 : 6,
    md: oneThird ? 4 : 6,
    sm: oneThird ? 4 : 6,
  };
  const rightColumnConfig: ColumnResponsiveSizes = {
    xl: oneThird ? 8 : 6,
    lg: oneThird ? 8 : 6,
    md: oneThird ? 8 : 6,
    sm: oneThird ? 8 : 6,
  };

  return (
    <Section
      sectionClassName={(styles as never)[`twoTextImageColumnBG${backgroundColor}`]}
      className={clsx(styles.twoTextImageColumn, {
        [styles.twoTextImageColumnBorderTop]: borderTop,
        [styles.twoTextImageColumnBorderBottom]: borderBottom,
      })}
      innerGap
    >
      {title && <Column as={'h1'}>{title}</Column>}
      <Column className={styles.column} {...leftColumnConfig}>
        {leftImage && <Image className={styles.twoTextImageColumnImage} {...leftImage} />}
        {!leftImage && fillEmptyImageHeight && (
          <Image
            className={clsx(
              styles.twoTextImageColumnImage,
              styles.twoTextImageColumnImagePlaceholder
            )}
            {...rightImage}
          />
        )}
        {leftTextNode?.childMdx?.body && (
          <Column xl={9} lg={9} md={9} sm={9}>
            <MDXRenderer>{leftTextNode?.childMdx?.body}</MDXRenderer>
          </Column>
        )}
      </Column>
      <Column className={styles.column} {...rightColumnConfig}>
        {rightImage && <Image className={styles.twoTextImageColumnImage} {...rightImage} />}
        {!rightImage && fillEmptyImageHeight && (
          <Image
            className={clsx(
              styles.twoTextImageColumnImage,
              styles.twoTextImageColumnImagePlaceholder
            )}
            {...leftImage}
          />
        )}
        {rightTextNode?.childMdx?.body && (
          <Column xl={9} lg={9} md={9} sm={9}>
            <MDXRenderer>{rightTextNode?.childMdx?.body}</MDXRenderer>
          </Column>
        )}
      </Column>
    </Section>
  );
};

export default TwoTextImageColumn;

export const query = graphql`
  fragment TwoTextImageColumn on DatoCmsTwoTextImageColumn {
    __typename
    id
    title
    leftImage {
      ...Image
    }
    leftTextNode {
      childMdx {
        body
      }
    }
    rightImage {
      ...Image
    }
    rightTextNode {
      childMdx {
        body
      }
    }
    backgroundColor
    leftColumnWidth
    fillEmptyImageHeight
    borderTop
    borderBottom
  }
`;
