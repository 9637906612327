// extracted by mini-css-extract-plugin
export var spacing = "spacing-module--spacing--pgnU+";
export var spacingSizexsmall = "spacing-module--spacingSizexsmall--DEClm";
export var spacingSizesmall = "spacing-module--spacingSizesmall--aQJSR";
export var spacingSizemedium = "spacing-module--spacingSizemedium--wKDR+";
export var spacingSizelarge = "spacing-module--spacingSizelarge--XhSWA";
export var spacingBGlilac = "spacing-module--spacingBGlilac--jRZX+";
export var spacingBGmilk = "spacing-module--spacingBGmilk--DOHSA";
export var spacingBGwhite = "spacing-module--spacingBGwhite--2JZEv";
export var spacingBGflamenco = "spacing-module--spacingBGflamenco--GYW25";
export var spacingBGpinky = "spacing-module--spacingBGpinky--0VFTC";