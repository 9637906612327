import clsx from 'clsx';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Section from 'components/section';
import { graphql } from 'gatsby';
import React from 'react';

import * as styles from './two-text-column.module.scss';
import { MDXRenderer } from 'gatsby-plugin-mdx';

const TwoTextColumn: React.FC<GatsbyTypes.TwoTextColumnFragment> = ({
  borderTop = false,
  borderBottom = false,
  leftColumn,
  rightColumnNode,
  backgroundColor,
  leftColumnWidth = '',
}) => {
  const oneThird = /third/i.test(leftColumnWidth);
  const leftColumnConfig: ColumnResponsiveSizes = {
    xl: oneThird ? 4 : 6,
    lg: oneThird ? 4 : 6,
    md: oneThird ? 4 : 6,
    sm: oneThird ? 4 : 6,
  };
  const rightColumnConfig: ColumnResponsiveSizes = {
    xl: oneThird ? 8 : 6,
    lg: oneThird ? 8 : 6,
    md: oneThird ? 8 : 6,
    sm: oneThird ? 8 : 6,
  };

  return (
    <Section
      sectionClassName={(styles as never)[`twoTextColumnBG${backgroundColor}`]}
      className={clsx({
        [styles.twoTextColumnBorderTop]: borderTop,
        [styles.twoTextColumnBorderBottom]: borderBottom,
      })}
      innerGap
    >
      <Column className={styles.twoTextColumnLeft} {...leftColumnConfig}>
        {leftColumn && <div dangerouslySetInnerHTML={{ __html: leftColumn }} />}
      </Column>
      <Column {...rightColumnConfig}>
        {rightColumnNode?.childMdx?.body && (
          <Column xl={10} lg={10} md={10} sm={10}>
            <MDXRenderer>{rightColumnNode?.childMdx?.body}</MDXRenderer>
          </Column>
        )}
      </Column>
    </Section>
  );
};

export default TwoTextColumn;

export const query = graphql`
  fragment TwoTextColumn on DatoCmsTwoTextColumn {
    __typename
    id
    backgroundColor
    borderTop
    borderBottom
    leftColumnWidth
    leftColumn
    rightColumnNode {
      childMdx {
        body
      }
    }
  }
`;
