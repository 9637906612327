import { RouteUpdateArgs } from 'gatsby';

export const onRouteUpdate = ({ location, prevLocation }: RouteUpdateArgs) => {
  if ((window as any).dataLayer) {
    (window as any).dataLayer.push({
      event: 'virtualPageview',
      location,
      prevLocation,
    });
  }
};
