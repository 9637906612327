import Bevel from 'components/bevel';
import Image from 'components/image';
import Link, { LinkType } from 'components/link';
import { graphql } from 'gatsby';
import React from 'react';
import ArrowUp from 'images/svg/arrow-up.inline.svg';

import * as styles from './case-card.module.scss';

const CaseCard: React.FC<GatsbyTypes.CaseCardFragment> = ({
  id,
  featuredImage,
  title,
  model,
  slug,
}) => {
  return (
    <Link
      className={styles.caseCard}
      internalLink={
        {
          id,
          model,
          slug,
        } as LinkType
      }
    >
      <Image className={styles.caseCardImage} {...featuredImage} />
      <div className={styles.caseCardTitle}>
        {title && <h3 dangerouslySetInnerHTML={{ __html: title }} />}
        <h4 className={styles.caseCardLink}>
          <ArrowUp />
          View the case
        </h4>
      </div>
      <Bevel size={'small'} color={'milk'} position={'bottomRight'} />
    </Link>
  );
};

export default CaseCard;

export const query = graphql`
  fragment CaseCard on DatoCmsCase {
    __typename
    id
    slug
    title
    model {
      apiKey
    }
    featuredImage {
      isImage
      mimeType
      gatsbyImageData(placeholder: DOMINANT_COLOR, imgixParams: { ar: "1.775", fit: "crop" })
      alt
      url
    }
  }
`;
