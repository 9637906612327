import clsx from 'clsx';
import CaseCard from 'components/case-card';
import Column, { ColumnResponsiveSizes } from 'components/column';
import Section from 'components/section';
import { useEmblaCarousel } from 'embla-carousel/react';
import { graphql } from 'gatsby';
import PrevIcon from 'images/svg/prev.inline.svg';
import NextIcon from 'images/svg/next.inline.svg';
import React from 'react';

import * as styles from './case-link.module.scss';

const CaseLink: React.FC<GatsbyTypes.CaseLinkFragment> = ({ cases = [] }) => {
  const [scrollSnaps, setScrollSnaps] = React.useState<number[]>([]);
  const [selectedIndex, setSelectedIndex] = React.useState<number>(0);
  const [sliderRef, embla] = useEmblaCarousel({
    skipSnaps: false,
    dragFree: false,
    align: 'start',
    speed: 20,
  });
  const [prevBtnEnabled, setPrevBtnEnabled] = React.useState(false);
  const [nextBtnEnabled, setNextBtnEnabled] = React.useState(false);
  const scrollTo = React.useCallback((index) => embla && embla.scrollTo(index), [embla]);

  const onSelect = React.useCallback(() => {
    if (!embla) return;
    setSelectedIndex(embla.selectedScrollSnap());
    setPrevBtnEnabled(embla.canScrollPrev());
    setNextBtnEnabled(embla.canScrollNext());
  }, [embla, setSelectedIndex]);

  const scrollNext = React.useCallback(() => {
    if (embla?.canScrollNext()) {
      embla?.scrollNext();
    }
  }, [embla]);
  const scrollPrev = React.useCallback(() => {
    if (embla?.canScrollPrev()) {
      embla?.scrollPrev();
    }
  }, [embla]);

  React.useEffect(() => {
    if (!embla) return;
    onSelect();
    setScrollSnaps(embla.scrollSnapList());
    embla.on('select', onSelect);
  }, [embla, setScrollSnaps, onSelect]);

  const cardColumn: ColumnResponsiveSizes = {
    xl: 5,
    lg: 5,
    md: 6,
    sm: 6,
    xs: 11,
  };

  return (
    <Section className={styles.casesLink}>
      <div ref={sliderRef} className={styles.sliderViewport}>
        <div className={styles.sliderContainer}>
          {cases.map(
            (caseItem) =>
              caseItem && (
                <Column key={caseItem.id} className={clsx(styles.sliderSlide)} {...cardColumn}>
                  <CaseCard {...caseItem} />
                </Column>
              )
          )}
        </div>
      </div>
      <div className={styles.sliderControls}>
        <div className={styles.sliderControlsContainer}>
          {scrollSnaps.map(
            (_, index) =>
              cases[index] && (
                <button
                  key={`${cases[index]?.id}-dot`}
                  aria-label={`Go to slide number ${index + 1}`}
                  className={clsx(styles.sliderControlsDot, {
                    [styles.sliderControlsDotActive]: selectedIndex === index,
                  })}
                  onClick={() => scrollTo(index)}
                >
                  <div />
                </button>
              )
          )}
        </div>
        <div className={styles.sliderControlsNavigation}>
          <button
            aria-label={'Scroll to previous in case slide show'}
            onClick={scrollPrev}
            className={styles.sliderControlsButton}
            disabled={!prevBtnEnabled}
          >
            <PrevIcon />
          </button>
          <button
            aria-label={'Scroll to next in case slide show'}
            onClick={scrollNext}
            className={styles.sliderControlsButton}
            disabled={!nextBtnEnabled}
          >
            <NextIcon />
          </button>
        </div>
      </div>
    </Section>
  );
};

export default CaseLink;

export const query = graphql`
  fragment CaseLink on DatoCmsCaseLink {
    __typename
    id
    cases {
      ...CaseCard
    }
  }
`;
