import clsx from 'clsx';
import React, { ComponentPropsWithRef, ReactNode } from 'react';

import * as styles from './input-field.module.scss';

type InputFieldProps = {
  title?: ReactNode;
  description?: string;
  errorMessage?: string;
};

const InputFieldBase = (
  {
    title,
    description,
    errorMessage,
    required,
    ...props
  }: InputFieldProps & Omit<ComponentPropsWithRef<'input'>, keyof InputFieldProps>,
  ref?: ComponentPropsWithRef<'input'>['ref']
) => {
  return (
    <div
      className={clsx(styles.inputField, (styles as never)[`inputFieldType${props.type}`], {
        [styles.inputFieldHasError]: errorMessage,
      })}
    >
      {props.type !== 'checkbox' && (
        <label htmlFor={props.name}>
          <h4>
            {title} {required && <span>*</span>}
          </h4>
        </label>
      )}
      {description && <p>{description}</p>}
      <input ref={ref} id={props.name} {...props} />
      {props.type === 'checkbox' && (
        <label htmlFor={props.name}>
          <p className={styles.title}>
            {title} {required && <span>*</span>}
          </p>
        </label>
      )}
      {errorMessage && <small className={styles.errorMessage}>{errorMessage}</small>}
    </div>
  );
};

const InputField = React.forwardRef(InputFieldBase);
InputField.displayName = 'InputField';

export default InputField as typeof InputFieldBase;
