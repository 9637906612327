import clsx from 'clsx';
import React, { ComponentPropsWithRef } from 'react';

import * as styles from './section.module.scss';

export type SectionProps<T extends React.ElementType> = {
  as?: T;
  children?: React.ReactNode;
  innerGap?: boolean;
  innerGapX?: boolean;
  innerGapY?: boolean;
  fullWidth?: boolean;
  className?: string;
  sectionClassName?: string;
};

const SectionBase = <T extends React.ElementType = 'section'>(
  {
    as,
    children,
    innerGap = false,
    innerGapX = false,
    innerGapY = false,
    className,
    sectionClassName,
    fullWidth = false,
    ...props
  }: SectionProps<T> & Omit<ComponentPropsWithRef<T>, keyof SectionProps<T>>,
  ref?: ComponentPropsWithRef<T>['ref']
): JSX.Element => {
  const Component = as || 'section';
  const classNames = clsx(
    styles.section,
    sectionClassName,
    { [styles.sectionFullWidth]: fullWidth },
    { [styles.sectionMaxWidth]: !fullWidth },
    { [styles.sectionInnerGapX]: innerGap || innerGapX },
    { [styles.sectionInnerGapY]: innerGap || innerGapY },
    { [styles.sectionDefault]: !innerGap && !innerGapX && !innerGapY },
    !innerGap && className
  );
  const innerClassNames = clsx(styles.sectionInner, innerGap && className);

  return (
    <Component ref={ref} className={classNames} {...props}>
      <div className={innerClassNames}>{children}</div>
    </Component>
  );
};

const Section = React.forwardRef(SectionBase);
Section.displayName = 'Section';

export default Section as typeof SectionBase;
