import Column, { ColumnResponsiveSizes } from 'components/column';
import Dropzone from 'components/dropzone';
import InputField from 'components/input-field';
import Link, { LinkProps } from 'components/link';
import Section from 'components/section';
import TextArea from 'components/text-area';
import { graphql } from 'gatsby';
import LoadingIcon from 'images/svg/loader.inline.svg';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';

import * as styles from './form.module.scss';
import clsx from 'clsx';

type Inputs = {
  'form-name': string;
  name: string;
  email: string;
  'organization-title': string;
  tel: string;
  productDescription: string;
  volume: string;
  terms: boolean;
  file: File;
};
const requiredMsg = 'This field is required';

// @ts-ignore: Unreachable code error
const encode = (data) => {
  const formData = new FormData();
  Object.keys(data).forEach((k) => {
    if (k === 'file') {
      formData.append(k, data[k], (data[k] as File).name);
    } else {
      formData.append(k, data[k]);
    }
  });
  return formData;
};

const Form: React.FC<GatsbyTypes.FormFragment> = ({ termsPage, callbackTextNode }) => {
  const [sending, setSending] = React.useState<boolean>(false);
  const formRef = React.useRef<HTMLFormElement>();
  const [sent, setSent] = React.useState<boolean>(false);
  const {
    register,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<Inputs>();
  const fieldColumn: ColumnResponsiveSizes = {
    xl: 6,
    lg: 6,
    md: 6,
    sm: 6,
  };

  const onSubmit: SubmitHandler<Inputs> = (data, e) => {
    setSending(true);

    fetch('/', {
      method: 'POST',
      body: encode(data),
    })
      .then((res) => {
        setSending(false);
        if (res.ok) {
          setSent(true);
        } else {
          alert('Request took too long. Try uploading a smaller file.');
        }
      })
      .catch((err) => {
        setSending(false);
        console.error('Failed to submit form', err);
      });

    e?.preventDefault();
  };

  const handleFileDrop = React.useCallback(
    (files) => {
      if (files.length > 0) {
        setValue('file', files[0]);
      }
    },
    [setValue]
  );

  if (sent) {
    return (
      <Section innerGapX>
        <Column xl={6} lg={6} md={3} sm={4}></Column>
        <Column xl={6} lg={6} md={9} sm={8}>
          {callbackTextNode?.childMdx?.body && (
            <MDXRenderer>{callbackTextNode?.childMdx?.body}</MDXRenderer>
          )}
        </Column>
      </Section>
    );
  }

  return (
    <Section
      ref={formRef as React.RefObject<HTMLFormElement>}
      as={'form'}
      className={clsx(styles.form, { [styles.formLoading]: sending })}
      onSubmit={handleSubmit(onSubmit)}
      data-netlify="true"
      name="Try us form"
      netlify-honeypot="bot-field"
      innerGap
    >
      <input type="hidden" {...register('form-name')} value="Try us form" />
      {sending && <LoadingIcon className={styles.formLoadingIcon} />}
      <p style={{ overflow: 'hidden', width: '100%', height: '0', padding: '0' }}>
        <label>
          Don’t fill this out if you’re human: <input name="bot-field" />
        </label>
      </p>
      <Column {...fieldColumn}>
        <InputField
          title={"What's your name?"}
          required
          errorMessage={errors.name?.message}
          {...register('name', { required: requiredMsg })}
        />
      </Column>
      <Column {...fieldColumn}>
        <InputField
          title={'Email address'}
          type={'email'}
          required
          errorMessage={errors.email?.message}
          {...register('email', {
            required: requiredMsg,
            pattern: {
              value: /^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/,
              message: 'Must be a valid email address',
            },
          })}
        />
      </Column>
      <Column {...fieldColumn}>
        <InputField
          title={'Role / Position'}
          required
          errorMessage={errors['organization-title']?.message}
          {...register('organization-title', { required: requiredMsg })}
        />
      </Column>
      <Column {...fieldColumn}>
        <InputField
          title={'Phone number'}
          required
          errorMessage={errors.tel?.message}
          {...register('tel', {
            minLength: {
              value: 7,
              message: 'Must be a valid telephone number',
            },
            required: requiredMsg,
            pattern: {
              value: /^[0-9+#\-\s()]*$/,
              message: 'Must be a valid telephone number',
            },
          })}
        />
      </Column>
      <Column {...fieldColumn}>
        <TextArea
          title={'Product description'}
          required
          errorMessage={errors.productDescription?.message}
          {...register('productDescription', { required: requiredMsg })}
          description={'Briefly describe the purpose, area of usage and material.'}
        />
      </Column>
      <Column {...fieldColumn}>
        <TextArea
          title={'Volume'}
          required
          errorMessage={errors.volume?.message}
          {...register('volume', { required: requiredMsg })}
          description={'State estimated production volume to determine relevance'}
        />
      </Column>
      <Column>
        <Dropzone
          title={'Upload file'}
          name={'file'}
          className={styles.dropZone}
          onDrop={handleFileDrop}
          // 15MB limit
          maxSize={25 * 1000 * 1000}
        />
      </Column>
      <Column {...fieldColumn}>
        <InputField
          type={'checkbox'}
          required
          errorMessage={errors.terms?.message}
          {...register('terms', { required: ' ' })}
          title={
            <>
              {'I agree to the '}
              {termsPage && termsPage?.length >= 1 && (
                <Link as={'ALink'} {...(termsPage[0] as LinkProps)} target={'_blank'}>
                  {termsPage[0]?.text}
                </Link>
              )}
            </>
          }
        />
      </Column>
      <Column {...fieldColumn}>
        <button className={styles.formSubmitButton} type="submit">
          Submit
        </button>
      </Column>
    </Section>
  );
};

export default Form;

export const query = graphql`
  fragment Form on DatoCmsForm {
    __typename
    id
    callbackTextNode {
      childMdx {
        body
      }
    }
    termsPage {
      ...Link
    }
  }
`;
