import Column from 'components/column';
import Section from 'components/section';
import { graphql, Link, useStaticQuery } from 'gatsby';
import Logo from 'images/svg/icon.inline.svg';
import React from 'react';
import Spacing from 'sections/spacing';
import RenderBlocks, { Blocks } from 'utils/RenderModules';

import * as styles from './footer.module.scss';

const Footer: React.FC = () => {
  const { footer, site } = useStaticQuery<GatsbyTypes.FooterQuery>(graphql`
    query Footer {
      site {
        buildTime(formatString: "YYYY")
      }
      footer: datoCmsFooterMenu {
        body {
          ...TwoTextColumn
          ...IconTextList
          ...Spacing
        }
      }
    }
  `);

  return (
    <footer className={styles.footerWrapper}>
      <Spacing size={'large'} />
      {footer && <RenderBlocks data={footer?.body as Blocks[]} />}
      <Spacing size={'medium'} />
      <Section className={styles.footer} innerGapX>
        <Column xl={6} lg={6} md={6} sm={6} xs={3}>
          <Link to={'/'}>
            <Logo className={styles.footerLogo} />
          </Link>
        </Column>
        <Column xl={6} lg={6} md={6} sm={6} xs={9}>
          <div>
            <h3>z.trusion®</h3>
            <p>{`© ${site?.buildTime} Reliefed AB. All rights reserved.`}</p>
          </div>
        </Column>
      </Section>
    </footer>
  );
};

export default Footer;
