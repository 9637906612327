import Bevel from 'components/bevel';
import Column from 'components/column';
import Section from 'components/section';
import { graphql } from 'gatsby';
import OpenIcon from 'images/svg/open-icon.inline.svg';
import CloseIcon from 'images/svg/close-icon.inline.svg';
import React from 'react';
import { useSpring, animated } from 'react-spring';
import { useMeasure } from 'react-use';
import RenderBlocks, { Blocks } from 'utils/RenderModules';

import * as styles from './accordion.module.scss';
import clsx from 'clsx';
import AccordionContext from './reducer';

type AccordionProps = Partial<GatsbyTypes.AccordionFragment> & {
  index: number;
  blocks?: Blocks[];
  endObject?: GatsbyTypes.AccordionEndFragment;
};

const Accordion: React.FC<AccordionProps> = ({ title, blocks = [], index, endObject }) => {
  const [isOpen, setIsOpen] = React.useState<boolean>(index === 1);
  const [ref, { height }] = useMeasure<HTMLDivElement>();
  const [contentHeight, setContentHeight] = React.useState<number>(height);

  const { state, dispatch } = React.useContext(AccordionContext);

  const style = useSpring({
    height: isOpen ? `${contentHeight}px` : '0px',
    config: {
      bounce: 0,
      duration: 400,
      easing: (x) =>
        isOpen ? (x === 0 ? 0 : Math.pow(2, 10 * x - 10)) : x === 1 ? 1 : 1 - Math.pow(2, -10 * x),
    },
  });

  const handleOpen = React.useCallback(() => {
    if (endObject) {
      dispatch({
        type: 'toggle',
        accordionId: endObject.id,
        tabIndex: isOpen ? -1 : index,
      });
    }
    setIsOpen((o) => !o);
  }, [dispatch, endObject, index, isOpen]);

  React.useEffect(() => {
    if (endObject?.showOnlyOne) {
      dispatch({
        type: 'toggle',
        accordionId: endObject.id,
        tabIndex: 1,
      });
    }
  }, [dispatch, endObject?.id, endObject?.showOnlyOne]);

  React.useEffect(() => {
    if (endObject?.showOnlyOne) {
      const accordionState = state.accordionStates.find((s) => s.id === endObject.id);

      setIsOpen(accordionState?.openTabIndex === index);
    }
  }, [endObject?.id, endObject?.showOnlyOne, state.accordionStates, index]);

  React.useEffect(() => {
    setContentHeight(height);
    window.addEventListener('resize', () => setContentHeight(height));

    return () => {
      window.removeEventListener('resize', () => setContentHeight(height));
    };
  }, [height]);

  if (blocks.length <= 0) return null;

  return (
    <Section className={clsx(styles.accordion, { [styles.accordionOpen]: isOpen })}>
      <Column
        as={'button'}
        aria-label={`Open accordion slide: ${title}`}
        className={styles.accordionButton}
        onClick={handleOpen}
      >
        <Bevel size={'small'} color={'milk'} position={'topLeft'} />
        <Column className={styles.accordionButtonColumn}>
          <div className={styles.accordionButtonColumnLeft}>
            <h1>{String(index).padStart(2, '0')}</h1>
            <div className={clsx(styles.accordionButtonIcon, styles.accordionButtonIconMobile)}>
              {isOpen ? <CloseIcon /> : <OpenIcon />}
            </div>
          </div>
          {title && (
            <div className={styles.accordionButtonColumnCenter}>
              <h3 dangerouslySetInnerHTML={{ __html: title }} />
            </div>
          )}
          <div className={clsx(styles.accordionButtonIcon, styles.accordionButtonIconDesktop)}>
            {isOpen ? <CloseIcon /> : <OpenIcon />}
          </div>
        </Column>
      </Column>
      <Column>
        <animated.div style={style} className={styles.accordionInner}>
          <div ref={ref}>
            <RenderBlocks data={blocks} />
          </div>
        </animated.div>
      </Column>
    </Section>
  );
};

export default Accordion;

export const query = graphql`
  fragment AccordionEnd on DatoCmsAccordionEnd {
    __typename
    id
    showOnlyOne
  }
  fragment Accordion on DatoCmsAccordionStart {
    __typename
    id
    title
  }
`;
